import React from 'react';
import '../components/text_display/Text_display.css';

const PrivacyNotice = () => {
return (
    <div className="privacy-notice-container">
        <h1 className="privacy-header">Impressum</h1>
        <h1 className="privacy-subheader">Anbieterkennung gem. §5 TMG</h1>
        <p className="privacy-text">
            Geigenbaumeister Andreas Jacobi<br />
            Bischof-Blum-Platz 9<br />
            65366 Geisenheim /Rheingau -Germany-<br />
            Tel.: 0049-(0)6722-971982<br />
            Fax: 0049-(0)6722-971984<br />
            E-Mail: kontakt@geigenbau-jacobi.de
        </p>
        <p className="privacy-text">
            <a href="/disclaimer">Haftungsausschluss, Datenschutz, Copyright</a>
        </p>
        <p className="privacy-text">
            Inhaltlich verantwortlich: Andreas Jacobi
        </p>
    </div>
);
};

export default PrivacyNotice;
