// src/components/cookies_disclaimer/cookies_disclaimer.js
import React, { useState, useEffect } from 'react';
import './cookies_disclaimer.css';

const CookiesDisclaimer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookies-banner">
      <div className="cookies-message">
        <p>Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie hier:</p>
        <a className="link-text" href="/disclaimer">Haftungsausschluss</a>
        <a className="link-text" href="/privacy_notice">Datenschutzerklärung</a>
        <div className="cookies-buttons">
          <button onClick={handleAccept}>Zustimmen</button>
          <button onClick={handleDecline}>Ablehnen</button>
        </div>
      </div>
    </div>
  );
};

export default CookiesDisclaimer;
