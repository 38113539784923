import React, { useState, useEffect } from 'react';
import './slideshow.css';

const Slideshow = ({ folderKey, title }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const imagesToShow = 3; // Number of images to show at once

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);

    let images = [];
    try {
      if (folderKey === 'slideshow1') {
        images = importAll(require.context('../images/slideshow1', false, /\.(png|jpe?g|svg)$/));
      } else if (folderKey === 'slideshow2') {
        images = importAll(require.context('../images/slideshow2', false, /\.(png|jpe?g|svg)$/));
      }
      setSlides(images);
      console.log("Loaded slides:", images); // Debugging output
    } catch (error) {
      console.error("Error loading images:", error);
    }
  }, [folderKey]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + imagesToShow) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - imagesToShow + slides.length) % slides.length);
  };

  useEffect(() => {
    const autoSwitch = setInterval(() => {
      nextSlide();
    }, 4000); // 3 seconds interval

    return () => clearInterval(autoSwitch); // Clean up the interval on component unmount
  }, [slides]);

  const visibleSlides = [];
  for (let i = 0; i < imagesToShow; i++) {
    const index = (currentSlide + i) % slides.length;
    visibleSlides.push(slides[index]);
  }

  return (
    <div className="slideshow-wrapper">
      {title && <h2 className="slideshow-title">{title}</h2>}
      {slides.length > 0 ? (
        <div className="slideshow-container">
          <button onClick={prevSlide} className="slideshow-button slideshow-prev">❮</button>
          <div className="slideshow-images">
            {visibleSlides.map((slide, index) => (
              <img key={index} src={slide} alt={`Slide ${index}`} className="slideshow-image" />
            ))}
          </div>
          <button onClick={nextSlide} className="slideshow-button slideshow-next">❯</button>
        </div>
      ) : (
        <p>No images found in the folder.</p>
      )}
    </div>
  );
};

export default Slideshow;
