import React from 'react';
import { useTranslation } from 'react-i18next';
import './Company_display.css';
import Image1 from './images/image1.jpg'; 
import Image3 from './images/image3.jpg';
import Image4 from './images/image4.png';
import Image5 from './images/image5.png';
import Image6 from './images/image6.png';

import enTranslations from './en.json';
import deTranslations from './de.json';

const CompanyDisplay = () => {
  const { t, i18n } = useTranslation();

  i18n.addResourceBundle('en', 'companyDisplay', enTranslations, true, true);
  i18n.addResourceBundle('de', 'companyDisplay', deTranslations, true, true);

  // Function to replace newlines with <br />
  const renderTextWithBreaks = (text) => {
    return text.split('\n').map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="image-container">
          <img src={Image1} alt="Left Image" className="image" />
        </div>
        <div className="text-container">
          <p className="text">
            {renderTextWithBreaks(t('companyDisplay:welcome'))}
            <br /><br />
            {renderTextWithBreaks(t('companyDisplay:contact_info'))}
          </p>
        </div>
      </div>

      <div className="segment">
        <div className="videos-container">
          <div className="video-item">
            <a href="https://geigenbau-jacobi.myshopify.com/search?q=jebony&options%5Bprefix%5D=last" target="_blank" rel="noopener noreferrer">
              <img src={Image3} alt="Video 1" className="video-thumbnail" />
            </a>
          </div>
          <div className="video-item">
            <a href="https://cremonamusica.com" target="_blank" rel="noopener noreferrer">
              <img src={Image4} alt="Video 2" className="video-thumbnail" />
            </a>
          </div>
          <div className="video-item">
            <a href="https://music-china.hk.messefrankfurt.com/shanghai/en.html" target="_blank" rel="noopener noreferrer">
              <img src={Image5} alt="Video 3" className="video-thumbnail" />
            </a>
          </div>
          <div className="video-item">
            <a href="https://www.vsaweb.org" target="_blank" rel="noopener noreferrer">
              <img src={Image6} alt="Video 4" className="video-thumbnail" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDisplay;
