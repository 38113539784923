// src/pages/homepage.js
import React from 'react';
import LandingImage from '../components/landing_image/landing_image';
import Company_Display from '../components/company_display/company_display';

const HomePage = () => {
    return (
        <div>
            <LandingImage />
            <Company_Display />
        </div>
    );
}

export default HomePage;
