// src/pages/homepage.js
import React from 'react';
import BuyRent from '../components/buy&rent/buy&rent';

const HomePage = () => {
    return (
        <div>
            <BuyRent />
        </div>
    );
}

export default HomePage;
