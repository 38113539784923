import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for translations
import './analyse&document.css';
import Thumbnail1 from './images/thumbnail1.png'; 
import Thumbnail2 from './images/thumbnail2.png';
import Thumbnail3 from './images/thumbnail3.png';
import Thumbnail4 from './images/thumbnail4.png';
import Thumbnail5 from './images/thumbnail5.png';
import Thumbnail6 from './images/thumbnail6.png';
import Thumbnail7 from './images/thumbnail7.png';
import Slideshow from './slideshow/slideshow';

import enTranslations from './en.json'; // Import English translations
import deTranslations from './de.json'; // Import German translations

const AnalyseDocument = () => {
  const { t, i18n } = useTranslation(); // Translation hook

  // Add translations for this component
  i18n.addResourceBundle('en', 'analyseDocument', enTranslations, true, true);
  i18n.addResourceBundle('de', 'analyseDocument', deTranslations, true, true);

  return (
    <div className="analyse-document-page">
      
      {/* First Segment: Handwerk trifft Moderne + Thumbnails */}
      <div className="segment">
        <div className="text-container">
          <h1 className="analyse-title">{t('analyseDocument:title')}</h1>
          <p className="analyse-description">
            {t('analyseDocument:description')}
          </p>
        </div>
        <div className="videos-container">
          <div className="video-item">
            <a href="https://youtu.be/9meVRWltngI" target="_blank" rel="noopener noreferrer">
              <img src={Thumbnail1} alt="Video 1" className="video-thumbnail" />
            </a>
          </div>
          <div className="video-item">
            <a href="https://youtu.be/to374ErhWjQ" target="_blank" rel="noopener noreferrer">
              <img src={Thumbnail2} alt="Video 2" className="video-thumbnail" />
            </a>
          </div>
          <div className="video-item">
            <a href="https://youtu.be/fsekHU2B0FU" target="_blank" rel="noopener noreferrer">
              <img src={Thumbnail3} alt="Video 3" className="video-thumbnail" />
            </a>
          </div>
          <div className="video-item">
            <a href="https://sketchfab.com/3d-models/goffriller-boden-3a-f41c5ce328664215a8d0829daac27aa3" target="_blank" rel="noopener noreferrer">
              <img src={Thumbnail4} alt="Video 4" className="video-thumbnail" />
            </a>
          </div>
        </div>
      </div>

      {/* Second Segment: Slideshow */}
      <div className="slideshow-segment">
        <Slideshow folderKey="slideshow1" title={t('analyseDocument:slideshow1_title')} />
      </div>

      {/* Third Segment: Portfolio Data Analysis */}
      <div className="slideshow-segment">
        <Slideshow folderKey="slideshow2" title={t('analyseDocument:slideshow2_title')} />
      </div>

      {/* Fourth Segment: Portfolio 3D Printing */}
      <div className="segment">
        <div className="videos-container">
          <div className="video-item">
            <a href="https://sketchfab.com/3d-models/boden-dalla-costa-c13add3923864435a8c6115c3140b778" target="_blank" rel="noopener noreferrer">
              <img src={Thumbnail5} alt="Video 1" className="video-thumbnail" />
            </a>
          </div>
          <div className="video-item">
            <a href="https://sketchfab.com/3d-models/vsa-example-textured-scan-andreas-jacobi-aca11470877942fb83e548f8e1d25be2" target="_blank" rel="noopener noreferrer">
              <img src={Thumbnail6} alt="Video 2" className="video-thumbnail" />
            </a>
          </div>
          <div className="video-item">
            <a href="https://sketchfab.com/3d-models/vsa-example-textured-scan-andreas-jacobi-e08fb3d10e4444a8974170e7616059d8" target="_blank" rel="noopener noreferrer">
              <img src={Thumbnail7} alt="Video 3" className="video-thumbnail" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyseDocument;
