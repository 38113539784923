// src/App.js
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './components/header/header';
import Contact from './components/contact/contact';
import BuyAndRent from './pages/buy&rent';
import Homepage from './pages/homepage';
import Footer from './components/footer/footer';
import PrivacyNotice from './pages/privacy_notice';
import RepairAndRestore from './pages/repair&restore';
import AnalyseAndDocument from './pages/analyse&document';
import Imprint from './pages/imprint';
import Disclaimer from './pages/disclaimer';
import CookiesDisclaimer from './components/cookies-disclaimer/cookies_disclaimer'; // Import the CookiesDisclaimer component
import './i18n';

function App() {
  const { i18n } = useTranslation();

  return (
    <Suspense fallback="Loading...">
      <Router>
        <div className="App">
          <CookiesDisclaimer /> {/* Add the CookiesDisclaimer component */}
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/buy&rent" element={<BuyAndRent />} />
              <Route path="/privacy_notice" element={<PrivacyNotice />} />
              <Route path="/repair&restore" element={<RepairAndRestore />} />
              <Route path="/analyse&document" element={<AnalyseAndDocument />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
