import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for translations
import './landing_image.css';
import landingImage from './landing_image.jpg';

import enTranslations from './en.json'; // Import English translations
import deTranslations from './de.json'; // Import German translations

const LandingImage = () => {
  const { t, i18n } = useTranslation(); // Translation hook
  const [headerHeight, setHeaderHeight] = useState(0);

  // Add translations for this component
  i18n.addResourceBundle('en', 'landingImage', enTranslations, true, true);
  i18n.addResourceBundle('de', 'landingImage', deTranslations, true, true);

  useEffect(() => {
    // Get the height of the header
    const header = document.querySelector('.header');
    if (header) {
      setHeaderHeight(header.offsetHeight);
    }
  }, []);

  return (
    <div
      className="landing-image"
      style={{
        backgroundImage: `url(${landingImage})`,
        height: `calc(100vh - ${headerHeight}px)`, // Set the height dynamically
      }}
    >
      <div className="text-overlay">
        <span className="handwerk-text">{t('landingImage:handwerk')}</span>
        <span className="trifft-text">{t('landingImage:trifft')}</span>
      </div>
      <div className="moderne-text-overlay">
        <span className="moderne-text">{t('landingImage:moderne')}</span>
      </div>
      <div className="button-container">
        <a href="/repair&restore" className="button">
          <span>{t('landingImage:repair_restore')}</span>
        </a>
        <a href="/buy&rent" className="button">
          <span>{t('landingImage:buy_rent')}</span>
        </a>
        <a href="/analyse&document" className="button">
          <span>{t('landingImage:analyse_document')}</span>
        </a>
      </div>
    </div>
  );
};

export default LandingImage;
