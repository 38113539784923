// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Initialize i18n with language detection using cookies
i18n
  .use(LanguageDetector) // Add the language detector plugin
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    fallbackLng: 'en', // Default language
    detection: {
      // Configure how to detect user language
      order: ['cookie', 'localStorage', 'navigator'], // Detection order
      caches: ['cookie'], // Cache the selected language in cookies
      cookieMinutes: 10080, // 7 days in minutes (default is 10 minutes)
      cookieOptions: { path: '/', sameSite: 'strict' }, // Cookie settings
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;










/* Original code */


/*

// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Initialize i18n and set the default language to English
i18n.use(initReactI18next).init({
  lng: 'en', // default language
  fallbackLng: 'en', // use English if the translation is not available
  interpolation: {
    escapeValue: false, // not needed for React as it escapes by default
  },
});

export default i18n;*/