import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import the hook for translations
import './Header.css';
import logo from './logo.png';

import enTranslations from './en.json'; // Import English translations
import deTranslations from './de.json'; // Import German translations

const Header = () => {
  const { t, i18n } = useTranslation(); // Use translation hook

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  // Add the translations for this component
  i18n.addResourceBundle('en', 'header', enTranslations, true, true);
  i18n.addResourceBundle('de', 'header', deTranslations, true, true);

  const toggleMobileMenu = () => {
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false);
      setTimeout(() => {
        setIsAnimating(false);
      }, 300);
    } else {
      setMobileMenuOpen(true);
    }
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  // Get the available language option that is not currently selected
  const availableLanguages = i18n.language === 'en' ? 'de' : 'en';

  return (
    <header className="header">
      <nav className="navbar">
        <div className="logo-container">
          <a href="/" className="logo-link">
            <img src={logo} alt={t('header:logo_alt')} className="logo" />
          </a>
        </div>
        <ul className={`nav-links ${isMobileMenuOpen || isAnimating ? 'mobile-open' : ''}`}>
          <li><a href="/">{t('header:menu_home')}</a></li>
          <li><a href="/contact">{t('header:menu_contact')}</a></li>

          {/* Language Switcher grouped with Home and Contact */}
          <li className="menu">
            <a href="#">
              {i18n.language.toUpperCase()}
            </a>
            <ul className="dropdown-language">
              <li>
                <a href="#" onClick={() => changeLanguage(availableLanguages.toLowerCase())}>
                  {availableLanguages.toUpperCase()}
                </a>
              </li>
            </ul>
          </li>

          <div className="divider"></div>

          <li className={`menu ${!isAnimating ? 'visible' : ''}`}>
            <a href="#">{t('header:menu_portfolio')}</a>
            <ul className="dropdown">
              <li><a href="/buy&rent">{t('header:menu_buy_rent')}</a></li>
              <li><a href="/repair&restore">{t('header:menu_repair_restore')}</a></li>
              <li><a href="/analyse&document">{t('header:menu_analyse_document')}</a></li>
            </ul>
          </li>

          <div className={`mobile-portfolio ${isMobileMenuOpen || isAnimating ? 'visible' : ''}`}>
            <li><a href="/buy&rent">{t('header:menu_buy_rent')}</a></li>
            <li><a href="/repair&restore">{t('header:menu_repair_restore')}</a></li>
            <li><a href="/analyse&document">{t('header:menu_analyse_document')}</a></li>
          </div>
        </ul>

        <div className={`mobile-language-toggle`} onClick={() => changeLanguage(availableLanguages.toLowerCase())}>
                {i18n.language.toUpperCase()}
          </div>

        <div className="hamburger" onClick={toggleMobileMenu}>
          <span className={`line ${isMobileMenuOpen ? 'open' : ''}`}></span>
          <span className={`line ${isMobileMenuOpen ? 'open' : ''}`}></span>
          <span className={`line ${isMobileMenuOpen ? 'open' : ''}`}></span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
