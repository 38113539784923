// src/pages/homepage.js
import React from 'react';
import AAndD from '../components/analyse&document/analyse&document';

const AnalyseAndDocument = () => {
    return (
        <div>
            <AAndD />
        </div>
    );
}

export default AnalyseAndDocument;
