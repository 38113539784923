// src/pages/homepage.js
import React from 'react';
import RepairAndRestore from '../components/repair&restore/repair&restore';

const repairAndrestore = () => {
    return (
        <div>
            <RepairAndRestore />
        </div>
    );
}

export default repairAndrestore;