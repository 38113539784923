import React from 'react';
import { FaYoutube, FaInstagram, FaFacebook } from 'react-icons/fa'; // Social Media Icons
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for translations
import './Footer.css'; // Import the CSS file

import enTranslations from './en.json'; // Import English translations
import deTranslations from './de.json'; // Import German translations

const Footer = () => {
  const { t, i18n } = useTranslation(); // Translation hook

  // Add translation resources for this component
  i18n.addResourceBundle('en', 'footer', enTranslations, true, true);
  i18n.addResourceBundle('de', 'footer', deTranslations, true, true);

  return (
    <footer className="footer">
      <div className="left-part">
        <div className="text-buttons">
          <a href="/contact" className="footer-link">{t('footer:contact')}</a>
          <a href="/privacy_notice" className="footer-link">{t('footer:privacy_policy')}</a>
          <a href="/imprint" className="footer-link">{t('footer:imprint')}</a>
          <a href="/disclaimer" className="footer-link">{t('footer:disclaimer')}</a>
        </div>
      </div>
      <div className="right-part">
        <div className="social-icons">
          <a href="https://www.youtube.com/@Jacobi58" className="social-icon" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
          <a href="https://www.instagram.com/jacobi_geigenbau/" className="social-icon" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.facebook.com/GeigenbauJacobi/?locale=de_DE" className="social-icon" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
        </div>
        <div className="copyright">
          {t('footer:copyright')}
        </div>
      </div>
    </footer>
  );
};

export default Footer;